/*eslint no-unused-vars: ["error", { "varsIgnorePattern": "[iF]ragment" }]*/

import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import SiteVariablesFragment from "src/components/QueryFragments"
import { ResourcesPageFragment } from "src/components/QueryFragments"

import "src/assets/scss/material-kit-react.scss"

import ResourcesPageView from "../views/ResourcesPage"

const ResourcesPage = ({ location }) => {
  const {
    contentfulWebsiteGlobalVariables,
    contentfulPageResources
  } = useStaticQuery(
    graphql`
      query {
        contentfulWebsiteGlobalVariables {
          ...SiteVariablesFragment
        }
        contentfulPageResources {
          ...ResourcesPageFragment
        }
      }
    `
  )

  const View = () => {
    if (contentfulPageResources !== null) {
      return (
        <ResourcesPageView
          siteVariables={contentfulWebsiteGlobalVariables}
          pageContent={contentfulPageResources}
          location={location}
        />
      )
    } else {
      /* redirect to 404 page */
      return <></>
    }
  }
  return <View />
}
export default ResourcesPage
