import {
  primaryTextColor,
  defaultMobileFontSize,
  defaultDesktopFontSize,
} from "src/assets/jss/material-kit-react.js"
import defaultComponentStyles from "src/assets/jss/material-kit-react/defaultComponentStyles"

const resourcesSectionStyles = theme => ({
  ...defaultComponentStyles,
  resourcesSection: {
    "& .homes-field-set": {
      marginBottom: theme.spacing(4),
    },
    "& .MuiFormLabel-root.Mui-focused": {
      color: "#000",
    },
    "& .MuiInput-underline:after": {
      borderBottom: "2px solid " + primaryTextColor,
    },
    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
      borderColor: primaryTextColor,
      borderWidth: "2px",
    },
    "& .resource-listing-wrapper": {
      width: "100%",
      marginTop: "1rem",

      "& a": {
        color: primaryTextColor,
        fontSize: defaultMobileFontSize,
        textDecoration: "underline",
        [theme.breakpoints.up("sm")]: {
          fontSize: defaultDesktopFontSize,
        },
      },
      "& ul": {
        padding: "0",
        "& ul": {
          paddingLeft: theme.spacing(2),
        },
        "& li": {
          paddingRight: theme.spacing(2) + "px !important",
          listStyle: "none",
          marginBottom: theme.spacing(6),
          [theme.breakpoints.up("sm")]: {
            paddingRight: "0 !important",
          },
          "& h4": {},
          "& li": {
            display: "flex",
            marginBottom: ".75rem !important",
          },
        },
      },

      "& .resource-name": {
        marginRight: "auto",
      },
    },
  },
})

export default resourcesSectionStyles
