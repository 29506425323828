import React from "react"
import Helmet from "react-helmet"
import classNames from "classnames"

import { makeStyles } from "@material-ui/core/styles"
import Layout from "src/components/Layout"

import GlobalBaseState from "src/assets/js/GlobalBaseState.js"

// Sections for this page
import ResourcesSection from "./Sections/ResourcesSection"

import styles from "src/assets/jss/material-kit-react/views/resourcesPage"
const useStyles = makeStyles(styles)

export default function ResourcesPage({ pageContent, ...rest }) {
  const pageClasses = useStyles()
  const [globalState, setGlobalState] = React.useState(GlobalBaseState)
  const [dimensions, setDimensions] = React.useState(() => {
    if (globalState.isBrowser) {
      return {
        height: window.innerHeight,
        width: window.innerWidth,
      }
    } else {
      return {
        height: null,
        width: null,
      }
    }
  })

  React.useEffect(() => {
    if (!globalState.isBrowser) return

    function handleResize() {
      setDimensions({
        height: window.innerHeight,
        width: window.innerWidth,
      })
    }
    window.addEventListener("resize", handleResize)

    return _ => {
      window.removeEventListener("resize", handleResize)
    }
  })
  return (
    <Layout
      pageClasses={pageClasses}
      scaffolding={pageContent.scaffolding}
      dimensions={dimensions}
      globalState={globalState}
      setGlobalState={setGlobalState}
      {...rest}
    >
      {isLandscape => (
        <>
          <Helmet>
            <body
              className={classNames(pageClasses.body, "aboutpage-wrapper")}
            />
          </Helmet>
          <ResourcesSection
            pageContent={pageContent}
            siteVariables={rest.siteVariables}
            globalState={globalState}
          />
        </>
      )}
    </Layout>
  )
}
