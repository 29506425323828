import React from "react"
import { makeStyles } from "@material-ui/core/styles"
import classNames from "classnames"

// core components
import Card from "src/components/Card/Card.js"
import CardBody from "src/components/Card/CardBody.js"
import CardHeader from "src/components/Card/CardHeader.js"
import GridContainer from "src/components/Grid/GridContainer.js"
import GridItem from "src/components/Grid/GridItem.js"

import { Typography } from "@material-ui/core"

import styles from "src/assets/jss/material-kit-react/views/resourcesPage/sections/resourcesSectionStyles"
import cardStyles from "src/assets/jss/material-kit-react/components/cardStyle"
const useStyles = makeStyles(styles)
const useCardStyles = makeStyles(cardStyles)

const ResourcesSection = ({ pageContent, globalState }) => {
  const classes = useStyles()
  const cardClasses = useCardStyles()

  const { headerText_page, ref_resourcesCategories } = pageContent

  return (
    <Card className={classes.resourcesSection}>
      <CardHeader color="primary">{headerText_page}</CardHeader>
      <CardBody
        className={classNames(
          "cardBody",
          classes.cardBody,
          cardClasses.cardBody
        )}
      >
        <GridContainer direction="column">
          <GridItem
            className="resource-listing-wrapper"
            xs={12}
            sm={6}
            md={6}
            lg={4}
          >
            {ref_resourcesCategories &&
              ref_resourcesCategories.map((c, i) => {
                return (
                  <GridContainer key={i} direction="row" component="ul">
                    <GridItem component="li">
                      {ref_resourcesCategories.length > 1 && (
                        <Typography
                          variant="h4"
                          className="pageSubheader-root"
                          gutterBottom
                        >
                          {c.categoryName}
                        </Typography>
                      )}
                      <GridContainer direction="column" component="ul">
                        {c.ref_resources.map((r, i) => {
                          if (r.text_phone) {
                            return (
                              <GridContainer
                                key={i}
                                item
                                direction="row"
                                component="li"
                              >
                                <GridItem className="resource-name">
                                  {r.publicName}
                                </GridItem>
                                <GridItem>
                                  <a
                                    href={"tel:" + r.text_phone}
                                    title={
                                      "Open in phone application and call " +
                                      r.text_phone
                                    }
                                    data-analytics-label={
                                      "Reources link: call " + r.publicName
                                    }
                                    target="_blank"
                                    rel="noopener noreferrer"
                                  >
                                    {r.text_phone}
                                  </a>
                                </GridItem>
                              </GridContainer>
                            )
                          } else {
                            return (
                              <GridContainer
                                key={i}
                                item
                                direction="row"
                                component="li"
                              >
                                <GridItem className="resource-name">
                                  {r.publicName}
                                </GridItem>
                                <GridItem>
                                  <a
                                    href={r.text_url}
                                    title={"Navigate to " + r.publicName}
                                    data-analytics-label={
                                      "Reources link: navigate to " +
                                      r.publicName
                                    }
                                    target="_blank"
                                    rel="noopener noreferrer"
                                  >
                                    visit >
                                  </a>
                                </GridItem>
                              </GridContainer>
                            )
                          }
                        })}
                      </GridContainer>
                    </GridItem>
                  </GridContainer>
                )
              })}
          </GridItem>
        </GridContainer>
      </CardBody>
    </Card>
  )
}

export default ResourcesSection
